import {
  ArrowRightIcon,
  ChevronDoubleRightIcon,
  ChevronRightIcon,
} from '@heroicons/react/solid';
import type { GetStaticProps, InferGetStaticPropsType, NextPage } from 'next';
import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { RotatingLines } from 'react-loader-spinner';
import { dehydrate, QueryClient, useQuery } from 'react-query';
import { MainContainer } from '../components/Container';
import { ContentContainer } from '../components/Content';
import { BenefitComponent } from '../components/Landing';
import { getMainContentsApi } from '../lib/apis/projects';
import { Project } from '../lib/types/project';

const benefits = [
  {
    id: 1,
    imageSrc: `/assets/img/landing/use01-idea.png`,
    text: '나만의 아이디어를 세상 밖으로',
  },
  {
    id: 2,
    imageSrc: `/assets/img/landing/use02-github.png`,
    text: '깃허브 연동으로 쉽고 빠른 출시',
  },
  {
    id: 3,
    imageSrc: `/assets/img/landing/use03-https.png`,
    text: '사이트 주소도 자유롭게',
  },
  {
    id: 4,
    imageSrc: `/assets/img/landing/use04-seo.png`,
    text: 'SEO 자동화로 검색엔진 상위 노출',
  },
  {
    id: 5,
    imageSrc: `/assets/img/landing/use05-payment.png`,
    text: '배너광고를 통한 수익 창출',
  },
  {
    id: 6,
    imageSrc: `/assets/img/landing/use06-marketing.png`,
    text: '1 대 1 전담 마케팅 제공',
  },
];

interface HomeProps extends InferGetStaticPropsType<typeof getStaticProps> {}

const Home: NextPage<HomeProps> = () => {
  const [projects, setProjects] = useState<Project[]>([]);
  const { data, isLoading, isError } = useQuery<Project[], Error>(
    ['mainContents'],
    getMainContentsApi,
  );

  useEffect(() => {
    if (!isLoading || !isError) {
      if (data) {
        setProjects(data);
      }
    }
  }, [data, isLoading, isError]);

  return (
    <MainContainer
      header={{ title: '스위그 - 웹 콘텐츠 공유 및 호스팅 플랫폼' }}
    >
      <main className="relative py-24 md:py-10">
        {/* First Section */}
        <section className="relative mx-auto flex w-full max-w-6xl flex-col-reverse justify-between md:flex-row">
          {/* Left */}
          <div className="z-10 flex-1 py-10 text-center md:py-32 md:pl-6 md:text-left">
            <h1 className="font-logo text-lg font-bold text-swygBlue-500 sm:text-xl">
              Show What You Got
            </h1>
            <div className="mt-5">
              <div className="flex flex-col space-y-4 sm:space-y-10">
                <h2 className="flex flex-col justify-center space-y-2 text-xl font-bold sm:text-3xl md:justify-start md:space-x-0 md:space-y-4 md:text-4xl lg:text-5xl">
                  <span>웹 콘텐츠를 공유하고</span>
                  <span>직접 호스팅 해보세요</span>
                </h2>
                <h3 className="text-lg sm:text-2xl md:text-3xl">
                  스위그 가이드라인으로 누구나
                </h3>
                <h4 className="sm:text-base md:text-lg">
                  웹 콘텐츠를 즐기고 제작, 서비스 출시, 수익까지
                </h4>
              </div>
            </div>

            <div className="mt-12 flex justify-center space-x-4 md:justify-start">
              <Link href="https://teamswyg.notion.site/SWYG-4673dcfd50024f45af908bd80e44d5dd?pvs=4">
                <a
                  className="btn-container border border-swygBlue-500 bg-white font-bold text-swygBlue-500 hover:opacity-70 active:opacity-100"
                  target="_blank"
                >
                  호스팅 가이드
                </a>
              </Link>
              <Link href="/create">
                <a className="btn-container btn-animation flex items-center bg-swygBlue-500 font-bold text-gray-100">
                  시작하기
                </a>
              </Link>
            </div>
          </div>

          {/* Right */}
          <div className="relative flex flex-1 md:hidden">
            <div className="relative mx-auto h-60 w-full overflow-hidden sm:h-80 sm:w-3/4">
              <Image
                src={`/assets/img/landing/landing-image-no-bg.png`}
                alt="landing-image-together"
                layout="fill"
                objectFit="contain"
                quality={100}
                priority={true}
              />
            </div>
          </div>

          <div className="absolute hidden h-full w-full md:block">
            <div
              className="relative w-full translate-y-44 transform overflow-hidden"
              style={{ height: 450 }}
            >
              <Image
                src={`/assets/img/landing/landing-image.png`}
                alt="landing-image-together"
                layout="fill"
                objectFit="cover"
                objectPosition="bottom"
                quality={100}
                priority={true}
              />
            </div>
          </div>
        </section>

        <section className="mx-auto mt-10 max-w-6xl py-6 md:mt-16">
          <Link href={'/contents'}>
            <a className="px-6 text-lg font-bold md:text-xl">
              Made In <span className="font-logo">SWYG</span>
            </a>
          </Link>
          <div className="grid gap-x-10 gap-y-14 overflow-hidden px-6 py-8 sm:grid-cols-2 lg:auto-rows-0 lg:grid-cols-3 lg:grid-rows-1 lg:pb-0">
            {projects && projects.length > 0 ? (
              projects.map((project) => (
                <ContentContainer
                  key={`main-project-${project.id}`}
                  projectId={project.id}
                  imageUrl={
                    project.projectImages?.find((image) => image.isThumbnail)
                      ?.imageFileLocation
                  }
                  tagString={project.tagString}
                  siteUrl={project.siteUrl}
                  summary={project.summary}
                  title={project.title}
                  onBoardDate={project.onBoardDate}
                />
              ))
            ) : (
              <div className="flex space-x-4 py-6 text-lg">
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="1"
                  width="26"
                />
                <div>웹 콘텐츠를 불러오는 중입니다...</div>
              </div>
            )}
          </div>
          <div className="flex justify-end px-6">
            <Link href={`/contents`}>
              <a className="group inline-flex items-center space-x-2">
                <span className="z-10 bg-white text-base font-bold">
                  더 많은 콘텐츠 보러가기
                </span>
                <div className="relative flex">
                  <ChevronRightIcon className=" h-8 w-8 transform duration-300 group-hover:translate-x-10 group-hover:opacity-0" />
                  <ChevronDoubleRightIcon className="absolute h-8 w-8 -translate-x-20 transform opacity-0 duration-500 group-hover:translate-x-0 group-hover:opacity-100" />
                </div>
              </a>
            </Link>
          </div>
        </section>

        <section className="mt-20 bg-swygBlue-50 bg-opacity-50 p-6 py-14 md:mt-20 md:py-20">
          <div className="mx-auto max-w-6xl sm:px-20">
            <div className="text-lg font-bold md:text-xl">
              스위그는 어떤 플랫폼인가요?
            </div>
            <div className="grid grid-cols-1 place-items-center gap-y-16 pt-16 sm:grid-cols-2 lg:grid-cols-3">
              {benefits.map((benefit) => (
                <BenefitComponent
                  key={`benefit-${benefit.id}`}
                  imageSrc={benefit.imageSrc}
                  text={benefit.text}
                />
              ))}
            </div>
          </div>
        </section>

        <section className="mt-24 p-6">
          <div className="mx-auto grid max-w-6xl grid-cols-1 space-y-10 md:grid-cols-2 md:space-y-0">
            <div className="text-center text-xl md:self-center md:text-left md:text-2xl md:leading-10">
              <div>스위그에서 웹 사이트를 호스팅하고</div>
              <div>다양한 웹 콘텐츠를 만나보세요.</div>
            </div>
            <div className="space-y-8">
              <Link href="/create">
                <a className="flex justify-between rounded-lg border border-white bg-swygBlue-50 p-8 hover:border-gray-200 hover:bg-white">
                  <div className="space-y-2">
                    <div className="text-xl font-bold text-gray-700">
                      웹 제작자
                    </div>
                    <div className="text-sm">호스팅 하기</div>
                  </div>
                  <div className="self-center">
                    <ArrowRightIcon className="h-8 w-8 text-gray-700" />
                  </div>
                </a>
              </Link>
              <Link href="/contents">
                <a className="flex justify-between rounded-lg border border-white bg-swygBlue-50 p-8 hover:border-gray-200 hover:bg-white">
                  <div className="space-y-2">
                    <div className="text-xl font-bold text-gray-800">
                      이용자
                    </div>
                    <div className="text-sm">웹 콘텐츠 둘러보기</div>
                  </div>
                  <div className="self-center">
                    <ArrowRightIcon className="h-8 w-8 text-gray-700" />
                  </div>
                </a>
              </Link>
            </div>
          </div>
        </section>
      </main>
    </MainContainer>
  );
};

export const getStaticProps: GetStaticProps = async () => {
  const queryClient = new QueryClient();

  await queryClient.prefetchQuery<Project[], Error>(['mainContents'], () =>
    getMainContentsApi(),
  );

  return {
    props: {
      dehydratedState: dehydrate(queryClient),
    },
    revalidate: 60,
  };
};

export default Home;
