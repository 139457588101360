import Image from 'next/image';

interface BenefitComponentProps {
  imageSrc: string;
  text: string;
}

const BenefitComponent: React.FC<BenefitComponentProps> = ({
  imageSrc,
  text,
}) => {
  return (
    <div className="col-span-1 space-y-6">
      <div className="relative mx-auto h-16 w-16">
        <Image
          src={imageSrc}
          alt="free-icon"
          layout="fill"
          objectFit="contain"
        />
      </div>
      <div className="text-base">{text}</div>
    </div>
  );
};

export default BenefitComponent;
